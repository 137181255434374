.navigation {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 11%;
  padding-right: 11%;
  z-index: 999;
  .linenav{
    background-color: white;
width: 2px;
height: 28px;
margin-left: 15px;
  }
  .pronavlogo{
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    white-space: pre;
    align-items: center;
    .air {
      margin-left: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg{
        margin-left: 10px;
      }
    }

  }
  .isCurrent {
    color: #59a2dd;
    a {
      color: #59a2dd;
    }
  }
  .nav-button-handle {
    display: flex;
    align-items: center;
    margin-left: 3vw;
  }
  .left-side-nav {
    display: flex;
    align-items: center;
  }
  .Affiliates-nav {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 75px;
    cursor: pointer;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    width: 165px;
    height: 44px;
    background:transparent;
    border: 0.5px solid #fff;
    border-radius: 5px;
    border-radius: 25px;
    transition: all ease 0.4s;
    &:hover {
      transition: all ease 0.4s;
      transform: scale(1.03)
    }
  }

  
  .nav-invester {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    margin-right: 10px;
    font-size: 15px;
    line-height: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    color: #ffffff;
    cursor: pointer;
    width: 165px;
    height: 44px;
    width: 193px;
    justify-content: center;
    // border: 1px solid #ffffff;
    border-radius: 5px;

    border-radius: 25px;
    // border: 1px solid #ffffff;
    background-color: #3296CA;;
    transition: all ease 0.4s;
    &:hover {
      transform: scale(1.03);
      transition: all ease 0.4s;
    }
  }
  // .member-text-first{
  //   display: none !important;
  // }
  .navDropdwown {
    width: 433px;
    height: 399px;
    top: 2.1rem;
    left: -10.5vw;
    position: absolute;
    padding: 2rem;
    padding-left: 0px;
    padding-right: 0px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    .listDetail {
      height: 5rem;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      cursor: pointer;
      transition: all ease 0.4s;
      &:hover {
        transform: scale(1.03);
        transition: all ease 0.4s;
        background: rgba(50, 150, 202, 0.05);
      }
     
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #16171a;
      justify-content: flex-start;
    }
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #16171a;
    }
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      margin-left: 2rem;
      color: #16171a;
    }
  }
  li {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* or 281% */
    margin-left: 3vw;
    display: flex;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: all ease 0.4s;
    &:hover {
      transform: scale(1.03);
    }
  }
  ul {
    padding: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0;
  }
  a:hover {
    color: #fff;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}
.mobile-nav-air {
  display: none;
}

.drop-box {
  padding: 7px 0px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  border-left: 2px solid #fff;
  cursor: pointer;
}

.drop-img-box {
  transition: all ease 0.2s;
}

.drop-menu-box {
  display: flex;
  align-items: center;
  transition: all ease 0.2s;
}

.drop-menu-box:hover {
  transform: scale(1.1);
}

.drop-menu {
  background: transparent;
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.drop-menu-head {
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
}
.mobileDrawer {
  display: none;
}

@media only screen and (max-width: 991.9px) {
  .big-box3 {
    padding: 7%;
    background: #ffffff;
    border: 0px solid #e7e7e7;
    box-shadow: none;
    border-radius: 25px;
    position: static;
    right: 0;
    left: 0;
    top: 80px;
    z-index: 17;
    display: block;
    justify-content: space-between;
    width: max-content;
    margin: auto;
    width: 100%;
}

  .mobileDrawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 6rem;
    height: 100%;
    background: white;
    padding-top: 27px;
    position: fixed;
    // padding-left: 5%;
    // padding-right: 5%;
    width: 100%;
    top: 77px;
    z-index: 999;
    overflow: scroll;
    border-top: 10px solid #3296ca;
    .Affiliates-nav {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 75px;
      cursor: pointer;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      justify-content: center;
      width: 54%;
      height: 44px;
      background: #59a2dd;
      border: 0.5px solid #59a2dd;
      border-radius: 35px;
      margin: auto;
      margin-bottom: 1rem;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .nav-invester {
      font-family: "Montserrat";
      font-style: normal;
      cursor: pointer;
      font-weight: 700;
      margin-right: 10px;
      font-size: 15px;
      line-height: 75px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 0px;
      color: #ffffff;
      width: 100%;
      height: 44px;
      background-color: transparent;
      justify-content: center;

      border: 1px solid #ffffff;
      border-radius: 5px;
      border: 1px solid #182542;
      border-radius: 35px;
      color: #182542;
      margin-bottom: 0.6rem;
      &:hover {
        transform: translateY(-5px);
      }
    }
    ul {
      padding: 0;
    }
    li {
         height: 72px;
      width: 100%;
      text-decoration: none;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      z-index: 999;
      padding-left: 7%;
    padding-right: 7%;
      justify-content: space-between;
      margin-bottom: 0px;
      border-top: 1px solid #EFEFEF;
    }
    .isCurrent {
      background: rgba(24, 37, 66, 0.05);
      color: #182542;
    }
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #182542;
    }
  }

  .navigation {
    display: none;
  }
  .mobile-nav-air {
    padding-left: 7%;
    padding-right: 7%;
    position: fixed;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    height: 78px;

    background: #16171a;
    label {
      justify-content: center;
      background: #3296ca;
      opacity: 0.9;
      border-radius: 35px;
      width: 112px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 0;
      color: #ffffff;
      height: 32px;
    }
  }
}







// 

.nav-menu-container {
  width: 100vw;
}

.big-box1 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
  right: calc(8vw + 400px);
  z-index: 17;
}

.big-box2 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
  right: calc(8vw + 300px);
  z-index: 17;
}

.big-box3 {
  padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: fixed;
    right: 0;
    left: 0;
    top: 80px;
    z-index: 17;
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin: auto;
}

.menus-left {
  width: fit-content;
}

.menus-right {
  width: fit-content;
  margin-left: 40px;
  border-left: 1px solid #e7e7e7;
  padding-left: 40px;
}

.big-box4 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
  right: calc(4vw);
  z-index: 17;
}

.menu-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #182542;
}

.menu-desc {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  line-height: 13px;
  color: #182542;
}

.each-menu {
  cursor: pointer;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  &:hover {
    background-color: #e7e7e7;
    border-radius: 15px;
  }
}

.menus {
  width: fit-content;
  cursor: pointer;
  margin-top: 10px;
  padding: 20px;
  padding-left: 0px;
  display: flex;
  &:hover {
    background-color: #e7e7e7;
    border-radius: 15px;
    padding-left: 10px;
  }
}

.menu-img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.nn-main {
  position: fixed;
  height: 80px;
  top: 0px;
  width: 100%;
  // padding: 0px 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: white;
}

.nn-img {
  height: 40px;
}

.nn-left {
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.1);
    transition: all ease 0.6s;
  }
}

.nn-right {
  display: flex;
  align-items: center;
  height: 80px;
}
.menushow {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
  position: fixed;

  height: 80px;
}
.nn-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #343c5b;
  padding-left: 40px;

  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: all ease 0.3s;
  }
}

.nn-btn {
  margin-left: 40px;
  padding: 13px 18px;
  text-align: center;
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  opacity: 0.9;
  border-radius: 10px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.nn-btn::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

.nn-btn:hover::after {
  left: 120%;
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 50;
}

//   .nn-box-main{
//     height: 200px;
//     width: 300px;
//     border-radius: 20px;
//     background-color: black;
//   }

.socialmedia {
  display: flex;
  margin-top: 1.7rem;
  align-items: center;
  .box {
    width: 87.73px;
    height: 87.73px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 15px;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: 756px) {
  .menushow {
    display: none;
  }
}

.navbar-main {
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 20;
  background-color: rgba(22, 23, 26, 1);
}
.dashboard-nav-main {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
}
.nav-left:hover {
  transform: scale(1.1);
  transition: all ease 0.6s;
}

.dashboard-navbar {
  height: 80px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   padding: 0px 5vw;
  .nav-right {
    height: 70px;
    display: flex;
    align-items: center;
  }
}

.menu-titles {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-right: 45px;
  cursor: pointer;
  &:nth-child(5) {
    margin-right: 0px;
  }
  &:hover {
    transform: scaleX(1.1);
    transition: all ease 0.4s;
  }
}

.nav-btn {
  height: 50px;
  width: fit-content;
  width: 174.25px;
  height: 50px;
  background: linear-gradient(
    274.71deg,
    #6262d9 0%,
    #6262d9 0.01%,
    #9d62d9 99.86%,
    rgba(255, 255, 255, 0) 99.86%
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover {
    transform: scale(1.1);
    transition: all ease 0.6s;
  }
}

// .nav-btn::before {
//   background: #fff;
//   bottom: 0;
//   content: "";
//   height: 91%;
//   left: -80%;
//   margin: auto;
//   position: absolute;
//   top: 0;
//   transform: rotate(45deg);
//   transform-origin: center center;
//   width: 88%;
// }

// .nav-btn:hover::before {
//   left: 220%;
//   transition: all 0.6s ease;
// }


@media only screen and (max-width: 991.9px) {
  .pronavlogo{
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    white-space: pre;
    align-items: center;
    .air {
      margin-left: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg{
        margin-left: 10px;
      }
    }

  }
  .big-box3 {
    padding: 7%;
    background: #ffffff;
    border: 0px solid #e7e7e7;
    box-shadow: none;
    border-radius: 25px;
    position: static;
    right: 0;
    left: 0;
    top: 80px;
    z-index: 17;
    display: block;
    justify-content: space-between;
    width: max-content;
    margin: auto;
    width: 100%;
    padding-top: 0;
}
.menus {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-top: 10px;
  padding: 20px;
  display: flex;
padding-left: 0px;
  margin: 0;
  margin-bottom: 1rem;
}


}