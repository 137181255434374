.core-content {
    border-radius: 25px 25px 25px 25px;
    width: 28%;
    margin-bottom: 2rem;
    border: 0.5px solid #E5E5E5;

    &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;

        .core-image {
            transition: all 0.2s ease-out;

            img {
                width: 71%;
            }
        }

        .conent {
            transition: all 0.2s ease-out;

            .label2 {
                font-size: 17px;
            }

            h1 {
                font-size: 36px;
            }

            p {
                font-size: 15px;
            }

            h5 {
                font-size: 17px;
            }
        }
    }

    .core-image {

        height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 70%;
        }
    }

    .conent {
        border-top: 0.1px solid #E5E5E5;
        padding: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 230px);



        .label2 {
            background: #4CAF50;

            border-radius: 15px;
            margin-bottom: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            margin-bottom: 1rem;
            margin-top: 3rem;
            color: #FFFFFF;
            height: 45px;
            width: 180px;
            justify-content: center;
            z-index: 20;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: 1s;

            &:hover {
                font-size: 17px;

            }

            &:after {
                background: #fff;
                content: "";
                height: 155px;
                left: -75px;
                opacity: 1;
                position: absolute;
                top: -50px;
                width: 50px;
                -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                -webkit-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                transform: rotate(35deg);
                z-index: -10;
            }

            &:hover:after {
                left: 120%;
                -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            }

            &:active {
                background: #4CAF50;

            }

            &:focus {}
        }

        h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            color: #5F6163;
        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 100;
            font-size: 14px;
            line-height: 35px;
            /* or 250% */
            margin-top: 2.5rem;
            text-align: center;

            color: #5F6163;
        }

        h5 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #5F6163;

            &::first-letter {
                text-transform: uppercase !important
            }

        }

    }
}

.section-core {
    margin-top: 13.2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 7.9%;
}

.section-core {
    padding: 0 90px;
}

@media screen and (min-width:1600px) {
    .section-core {
        padding: 0 140px;
    }

}

@media screen and (max-width:1200px) {
    .section-core {
        margin-top: 13.2rem;
        display: flex;
        flex-wrap: nowrap;
        gap: 7.9%;
        overflow: scroll;
    }

    .core-content {
        border-radius: 25px 25px 25px 25px;
        width: 350px;
        margin-bottom: 2rem;
        border: 0.5px solid #E5E5E5;

        .conent {
            width: 350px;
        }
    }

    .section-core {
        padding: 0 42.1px;
    }

}

@media screen and (max-width:500px) {
    .section-core {
        margin-top: 10.2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0%;

        .conent {
            width: 100%;
            padding: 1rem;
            padding-top: 1.3rem;

            .label2 {
                margin-top: 1rem;
            }

            p {
                font-size: 13px;

                margin-top: 1.5rem;
                line-height: 35px;
            }

            h1 {
                font-weight: 800;
                font-size: 27px;
                line-height: 33px;
            }
        }

    }

    .core-content {
        width: 100%;

        .core-image {
            align-items: center;
            display: flex;
            height: 170px;
            justify-content: center;
        }
    }
}


//   @media screen and (max-width:500px) {
//     .core-content{
//         width: 100%;
//     }
//     .section-core {
//         padding: 0 15px;
//     }

//   }