.GoogleMap {
  height: calc(100vh - 4rem);
  overflow: hidden;
  max-width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

svg {
  display: inline-block;
  vertical-align: middle;
}
