.offeringsPage {
    height: 100%;
    flex-grow: 1;
    display: flex;
    margin-top: 150px;

    .pageWrap {
        width: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        position: relative;

        .pageContent {
            height: 0;
            flex: 1;
            display: flex;

            .tableArea {
                width: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 40px 4% 0 4%;
                border: 0.5px solid #E7E7E7;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;

                .headerTabs {
                    height: 60px;
                    border-bottom: 0.5px solid #E7E7E7;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    overflow: hidden;
                    display: flex;

                    .tabItm {
                        flex: 1;
                        border-left: 0.5px solid #E7E7E7 !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                        color: #464B4E;
                        font-size: 13px;
                        cursor: pointer;
                        background: transparent;
                        border: none;

                        &:first-of-type {
                            border-left: none;
                        }

                        &.true {
                            padding: 0 30px;
                            font-weight: 700;
                            background: #4CAF50;
                            color: white;
                        }
                        &.active {
                            padding: 0 30px;
                            font-weight: 700;
                            background: #4CAF50;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.disp-tab-card{
    margin-top: 150px;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: 0.5px solid #E7E7E7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;
}

.headerTabs{
    display: flex;
    // justify-content: space-between;
    // border-left: 0.5px solid #E7E7E7 !important;
    height: 60px;
    border-bottom: 0.5px solid #E7E7E7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    align-items: center;
    text-align: center;
    // width: 12.5%;
}
.tabItm{
    // border-left: 0.5px solid #E7E7E7 !important;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #464B4E;
    font-size: 13px;
    cursor: pointer;
    width: 12.5%;
    &.true {
        padding: 20px 30px;
        font-weight: 700;
        background: #4CAF50;
        color: white;
    }
}
