.titleMain {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding-bottom: 3rem;
  padding-top: 3rem;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 0px;

    color: #15172a;
  }
  .inner {
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 15px;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 3rem;
  .tileName {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #15172A;
    width: 20%;
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(5) {
      width: 11%;
    }
  }
}

.sectoinshowtoggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 138px;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  cursor: pointer;
  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #15172A;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    /* or 180% */
    margin-bottom: 0;
    display: flex;
    padding-right: 4rem;
    align-items: center;
    color: #15172A;
  }
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #15172A;
  }
  label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(157.72deg, #DE1664 14.53%, #272C63 125.44%);
    border-radius: 15px;
    width: 126px;
    color: #ffffff;
    justify-content: center;
    &:hover {
      transform: scale(1.1);
    }
  }
  .contentItem {
    width: 20%;
    .inner {
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
      }
    }
    &:nth-child(1) {
      padding-left: 1.3%;
      width: 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(5) {
      width: 11%;
      padding-right: 1%;
    }
  }
}

.HoldingPage {
  padding-left: 1.3%;
  padding-top: 74px;
  padding-right: 1.3%;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 75px;
    /* or 69% */

    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background: linear-gradient(180deg, #de1664 0%, #272c63 172.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.titleMainCustom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding-bottom: 1.3rem !important;
  padding-top: 3rem;
}
