
.mobileText
{

display: none;
}
.stickyFooter {
  flex: 0 0 65px;
  max-height: 60px;
  // background: #4caf50;
  background: #16171A;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 19px;
  font-weight: 600;
  bottom: 0;
  position: fixed;
  z-index: 9;
  width: 100%;
  .footerWrap {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .raindrops {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 1%;
      width: 15%;
      padding-bottom: 10px;
    }
    &.sb {
      justify-content: space-between;
      .left,
      .right {
        height: 14px;
        width: 16px;
      }
      .banner {
        display: flex;
        align-items: center;
        height: 50px;
        width: fit-content;
        .logo {
          // height: 50px;
          // width: 50px;
          margin-right: 10px;
          height: 45%;
          object-fit: contain;
        }
        span {
          font-size: 1.2rem;
          font-weight: 700;
          color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg{
            margin-left: 10px;
            margin-right: 10px;
          }
          &:hover{
            transform: scale(1.03);
          }
        }
      }
    }
  }
}

.SectionThree {
  padding-top: 8rem;
  padding-left: 11%;
    padding-right: 11%;
  padding-bottom: 4rem;
  h6{
    color: #3296CA;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 25px;
margin-bottom: 0px;
text-align: left;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #16171a;
    font-size: 20px;
font-style: normal;
font-weight: 600;
font-size: 40px;
font-style: normal;
font-weight: 500;
justify-content: center;
text-align: center;
  }
  .listcustom{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
    .cutomh5{
      color: #16171A;
text-align: center;
font-family: Montserrat;
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 2rem;
margin-bottom: 2rem;
    }
    .customeh6{
      color: #16171A;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */
width: 342px;
    }
  }
  .titleseaactionthree{
    color: #16171A;
text-align: center;
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
margin-bottom: 5.5rem;
  }
  .mainlistshowthree{
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    width: 100%;
  }
  .listdetialcard{
    width: calc(100% - 519px);
    
    height: 556px;
flex-shrink: 0;
border-radius: 15px;
border: 1px solid #EFEFEF;
background: #FFF;
  }
  .CardSetion1 {
    // display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .CardSetion1cutom {
 display: flex;
 justify-content: space-between;
    gap: 1rem;
    justify-content: space-between;
    gap: 1rem;
    width: 80%;
    margin: auto;
  }
  .listleftsidecontent{
    margin-left: 17px;
  }
  .list {
    border: 1px solid #efefef;
    border-radius: 20px;
    padding: 2rem;
    height: 308px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 449px;
height: 164px;
  margin-bottom: 2rem;
  svg{
     height: 56.732px;
    width: 56.732px;

  }
    &:hover{
      transform: scale(1.02);
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #16171A;
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0rem;
      margin-bottom: 5px;
    }
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 0px;
      color: #ffffff;
      background: #3296ca;
      border-radius: 35px;
      justify-content: center;
      height: 62px;
      margin-bottom: 2.2rem;
    }
  }
}

.sectonSection {

  // height: calc(100vh - 88px);
  // overflow: scroll;
  
  background: white;
  position: relative;
  top: -1vh;
  border-radius: 15px 15px 0px 15px;
  // display: flex;
  // padding-left: 11%;
  padding-top: 2rem;
  // padding-right:11%;
  padding-bottom: 2rem;
  .sectionInnerTwo {
    height: 100%;
    // overflow: scroll;
    margin-top: 2rem;
    padding-bottom: 2rem;
   
  }
  .CardSetion {padding-left: 11%;
    padding-right: 11%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
padding-top: 9rem;

  }
  .cards {
    cursor: pointer;
    // width: 228px;
    height: 228px;
    background: #ffffff;
    border: 1.5px solid #efefef;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      height: 63px;
      margin-bottom: 1.5rem;
    }
    label {
      width: 155px;
      height: 42px;
      background: #3296ca;
      border-radius: 35px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: none;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-bottom: 0;
      margin-top: 1.1rem;
      color: #ffffff;

      justify-content: center;
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* or 500% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #16171a;
      margin-bottom: 0;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      /* or 320% */
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      color: #16171a;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      label {
        display: flex;
      }
      h1 {
        display: none;
      }
    }
  }
}


.AffilateSection{
  height: 517px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #16171A;
  margin-top: 8rem;
  margin-bottom: 8rem;
  padding-left: 11%;
  padding-right: 11%;
  .svg{
    position: absolute;
    right: calc(11%  - 42px);
    top: -33px;
    // width: 274.169px;
height: 579px;
  }
  label{
    margin-bottom: 0px;
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 222.933px;
height: 48.17px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50px;
background-color: #3296CA;
&:hover{
  transform: scale(1.1);
  cursor: pointer;
}
  }
  .desaffilate{
    color: #FFF;
font-family: Montserrat;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 35px;
width: 724px;
margin-top: 2rem;
margin-bottom: 2rem;
  }
  .subtext{
    color: #FFF;
font-family: Montserrat;
font-size: 25px;
font-style: italic;
font-weight: 700;
line-height: 40px; /* 160% */
svg{
  margin-left: 9px;
}
  }
  .textalignaffiliate{
    display: flex;
    align-items: center;
    color: #FFF;
font-family: Montserrat;
font-size: 55px;
font-style: normal;
font-weight: 600;
line-height: 49px;
    margin-bottom: 1rem;
svg{
  margin-left: 20px;
  margin-right: 20px;
}
  }
}
.BottomPage {
  display: flex;

  position: sticky;
  padding-left: 11%;
  padding-right: 11%;
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    cursor: pointer;
    margin-right: 1.5rem;
    color: #16171a;

    opacity: 0.5;
    margin-bottom: 0;
  }
}
.HomePageLanding .label-text {
  display: flex;
  align-items: center;
  margin-top: 2rem;

}
.HomePageLanding {
  // background-image: url("../img/homebck.png");
  height: calc(100vh - 12rem);
  display: flex;
  padding-top: 55px;
  justify-content: space-between;
  background: #16171a;
  width: 100%;
  padding-left: 11%;
  padding-right: 11%;
  position: relative;
  padding-bottom: 10rem;
  .sellcards{
   margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 78%;
    margin: auto;
    bottom: -84px;
    z-index: 9;
    left: 0;
    right: 0;
    .calling{
      display: none;
    }
    .cardsell{
      border-radius: 15px;

width: 32%;
height: 248.786px;
flex-shrink: 0;
padding-left: 1.8rem;
    padding-right: 1.8rem;
    display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
border: 0.5px solid #BEC6C6;
background: #FFF;
&:hover{
  border: 0.5px solid rgba(255, 255, 255, 0.05);
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
cursor: pointer;
&:hover{
  .textdiv{
  width: max-content;
  padding: 0 0.7rem;
border-radius: 30px;
}
.div{
display: block;
}
}
}
    }
    .selltitle{
      color: #16171A;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 1rem;
    }
    .descrtionsecc{
      color: #16171A;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 169.231% */
margin-bottom: 1rem;
height: 68px;

flex-shrink: 0;
    }
    .flexsell{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .textdiv{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #f3f4f5;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      cursor: pointer;
      transition: all ease-in 0.3s;
      svg{
        height: 16px;
      }
      &:hover{
     
      }
      div {
        display: none;
        font-size: 13px;
        font-weight: 700;
        
        color: #3296CA;
    }
    }

  }
  .leftSideSection {
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    .mobileVersion {
      display: none;
    }
  }
  .rightsideImage {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    img {
      height: 71vh;
      position: relative;

      bottom: -4rem;
      z-index: 9;
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;

    /* or 69% */
text-align: center;
    display: flex;
    align-items: center;
justify-content: center;
    color: #ffffff;
    margin-bottom: 10px;
    // span{
    //   color: #3296ca;
    //   margin-right: 15px;
    // }
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 75px;
    line-height: 65px;

    display: flex;
    align-items: center;
justify-content: center;
    color: #3296ca;
  }
  .investor {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 75px;
    /* or 375% */
    width: 231.4px;
    height: 65px;
    cursor: pointer;
    border: 1.5px solid #ffffff;
    border-radius: 10px;
    justify-content: center;
    background: transparent;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: #ffffff;
    margin-right: 10px;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .Affiliates {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 75px;
    /* or 375% */
    width: 231.4px;
    height: 65px;
    cursor: pointer;
    background: #59a2dd;
    border: 0.5px solid #59a2dd;
    border-radius: 10px;
    margin-bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .social-media {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 2rem;
  }
  .borderlessText {
    width: 2px;
    height: 63.32px;
    margin-left: 35px;
    margin-right: 35px;
    background: #fff;
  }
  .label-text {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    justify-content: center;
    img {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .img-text {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 24px;
    }
  }
  .watchbox {
    width: 358px;
    height: 84px;

    background: #151e27;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: translateY(-5px);
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 45px;
      /* or 205% */
      margin-bottom: 0;
      display: flex;
      align-items: center;

      color: #ffffff;

      margin-left: 10px;
    }
  }
  .box {
    width: 83.68px;
    height: 83.68px;

    justify-content: center;
    display: flex;
    align-items: center;
    background: #151e27;
    cursor: pointer;
    border-radius: 15px;
    margin-right: 10px;
    &:hover {
      transform: translateY(-5px);
    }
  }
}

.mission-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding-left: 5%;
  padding-right: 3.5%;
  h4 {
    display: none;
  }
  .missRadius {
    border-radius: 35px;
  }
  .button-handing {
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
    }
  }
  .lablemission {
    font-family: "Montserrat";
    font-style: normal;

    /* or 219% */
    width: 622px;
    height: 94px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    background: #ffffff;
    justify-content: space-between;
    border: double 0.5px transparent;
    border-radius: 30px;
    background-image: linear-gradient(white, white),
      linear-gradient(to right, #59a2dd, #81cbd7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 35px;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90.5deg, #59a2dd -29.61%, #81cbd7 110.39%);
      .inner {
        font-weight: 700;
        color: #fff;
      }
    }
    &:nth-child(3) {
      margin-bottom: 0px;
    }
    .inner {
      font-weight: 500;
      font-size: 32px;
      line-height: 70px;
      padding-left: 3rem;
    }
    .button-handing {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 70px;
      margin-right: 3rem;
      /* or 280% */

      display: flex;
      align-items: center;

      color: #ffffff;
      img {
        margin-left: 10px;
      }
    }
  }

  .left-mission {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-mission {
    display: flex;
    align-items: flex-end;
    height: 100vh;
    img {
      height: 87vh;
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 70px;
    /* or 127% */

    display: flex;
    margin-bottom: 11vh;
    align-items: center;

    color: #182542;
  }
  h2 {
    width: 665px;
    height: 93px;
    left: 44px;
    top: 522px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 40px;
    /* or 182% */

    display: flex;
    align-items: center;

    color: #182542;
    margin-bottom: 0;
  }
}

.AppSection {
  height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  .card-app {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 2%;
    height: 100vh;
    // overflow: scroll;
    cursor: pointer;
  }
  .innerCard {
    width: 32.65%;
    height: 350px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 6vh;
    padding-left: 6vh;
    &:hover {
      transform: translateY(-5px);
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    /* or 150% */

    display: flex;
    align-items: center;
    margin-top: 2rem;

    color: #343c5b;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 45px;
    line-height: 4px;
    line-height: 4px;

    /* or 250% */

    display: flex;
    align-items: center;

    color: #343c5b;
  }
}

.bannerbottomsection{
  height: 267px;
  background-color: #16171A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 4rem;
  margin-bottom: 2rem;
  .userNameSection{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 142.857% */
  }
  .number{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 45px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 1rem;

  }
}
@media only screen and (max-width: 991.9px) {
  .HomePageLanding .rightsideImage {
    display: none;
  }
  .SectionThree .list .title {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    color: #ffffff;
    background: #3296ca;
    border-radius: 35px;
    justify-content: center;
    height: 47px;
    margin-bottom: 2.2rem;
  }
  .BottomPage h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  .SectionThree {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.bannerbottomsection .userNameSection {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 7rem;
}

.bannerbottomsection {
  height: auto;
  background-color: #16171A;
  display: block;
  align-items: center;
  justify-content: space-between;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 4rem;
  padding-top: 7rem;
  padding-bottom: 5rem;
  margin-bottom: 2rem;
}

.sectonSection {
  background: white;
  position: relative;
  top: -1vh;
  border-radius: 15px 15px 0px 15px;

  padding-top: 2rem;

 

  padding-bottom: 2rem;
}
  .SectionThree .CardSetion1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin-bottom: 1rem;
  }
  .HomePageLanding .sellcards .descrtionsecc {
    color: #16171A;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 1rem;
    height: 68px;
    flex-shrink: 0;
}
  .SectionThree h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    color: #16171a;
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 33px;
  }
  .SectionThree
  
  {
    .listcustom 
    {
    svg{

    height: 5rem;
    height: 4rem;
    margin-bottom: 1rem;

  }
}
    
  }

  .SectionThree h6 {
    color: #3296CA;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    text-align: left;
}
.SectionThree .listcustom .cutomh5 {
  color: #16171A;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.SectionThree .CardSetion1cutom {
  display: block;
  justify-content: space-between;
  gap: 1rem;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin: auto;
}
.SectionThree .listcustom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.SectionThree .CardSetion1cutom {
  display: block;
  justify-content: space-between;
  gap: 1rem;
  justify-content: space-between;
  gap: 1rem;
  width: 80%;
  margin: auto;
}
.HomePageLanding {
  height: 80vh;
  display: flex;
  padding-top: 55px;
  justify-content: space-between;
  background: #16171a;
  width: 100%;
  padding-left: 6%;
  padding-right: 6%;
}


.SectionThree .listdetialcard {
  

  display: none;
}

  .SectionThree .list h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #16171A;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.3rem;
    margin-bottom: 5px;
}
  .SectionThree .list {
    border: 1px solid #efefef;
    border-radius: 20px;
    padding: 1rem;
    height: 308px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    height: 136px;
    margin-bottom: 2rem;
}
  .SectionThree .titleseaactionthree {
    color: #16171A;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    margin-bottom: 2.5rem;
}

.SectionThree .listcustom .cutomh5 {
  color: #16171A;
  text-align: center;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.SectionThree .listcustom .customeh6 {
  color: #16171A;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
  .HomePageLanding {
    height: auto;
padding-bottom: 2rem;
    display: block;
    .mobileVersion {
      padding-top: 3rem;
      margin: auto;
      display: flex !important;
      height: calc(100vh - 17rem);
    }
    h2 {
      font-size: 45px;
      line-height: 65px;
      font-size: 30px;
      text-align: center;
    }
    .leftSideSection {
      display: block;
      padding-top: 64px;
      text-align: center;
    }
    .label-text {
      display: none;
    
    }
  }
  .HomePageLanding .borderlessText {
    width: 2px;
    height: 42.32px;
  }
  .HomePageLanding .label-text img {
    cursor: pointer;
    height: 30px;
  }
  .HomePageLanding h1 {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 41px;
    justify-content: center;
  }
  .BottomPage {
    height: auto;
    background: white;
    position: relative;
    top: 0vh;
  }
  .sectonSection .CardSetion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .sectonSection .cards {
    cursor: pointer;
    height: 187px;
  }
  .sectonSection .sectionInnerTwo {
    height: 100%;
    margin-top: 2rem;
    padding-bottom: 2rem;

}
.sectonSection .CardSetion{
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 0;
}
.BottomPage {
  display: flex;
  position: sticky;
  padding-left: 6%;
  padding-right: 6%;
}
  .sectonSection .cards h1 {
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-size: 17px;
  }
  .sectonSection .cards img {
    height: 53px;
  }
  .sectonSection .cards h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  .AffilateSection .svg {
display: none;
}
.AffilateSection {
  height: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #16171A;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-left: 6%;
  padding-right: 6%;
}
.AffilateSection .subtext svg {
  margin-left: -27px;
  height: 26px;
}
.AffilateSection .subtext {
  color: #FFF;
  font-family: Montserrat;
  font-size: 21px;
  font-style: italic;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
}

.AffilateSection .desaffilate {
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.AffilateSection label {
  margin-bottom: 0px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 222.933px;
  height: 48.17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #3296CA;
  margin: auto;
  margin-top: 9px;
}

.AffilateSection .textalignaffiliate {
  display: block;
  align-items: center;
  text-align: center;
  color: #FFF;
  font-family: Montserrat;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 57px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  svg{
    
  }
}
.HomePageLanding .sellcards {
  margin: auto;
  display: block;
  align-items: center;
  justify-content: space-between;
  position: static;
  width: 100%;
  margin-top: 3rem;
}
.HomePageLanding .sellcards .selltitle {

color: white;
}
.HomePageLanding .sellcards .descrtionsecc {

  color: white;
  height: auto;
}

.HomePageLanding .sellcards .calling {
  display: block;
  margin-bottom: 1rem;
}
.HomePageLanding .sellcards .flexsell {
  display: none;

}
.HomePageLanding .sellcards .cardsell {
 
  width: 100%;
  margin-bottom: 2rem;

  padding-left: 1.8rem;
  padding-right: 1.8rem;
  
  border: 0px solid #BEC6C6;
  background: #FFF;
  background: rgba(255, 255, 255, 0.05);
  color: white;
}
.stickyFooter{
  display: none;
}
.HomePageLanding .sellcards .cardsell:hover {
  border: 0px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.AffilateSection .textalignaffiliate svg {
  margin-left: 0;
  margin-right: 1px;
  height: 33px;
}

  .sectonSection .cards label {
    width: 90%;
    font-size: 12px;
  }

  .mobileText
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 79px;
    background: #3296CA;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding-left: 6%;
    z-index: 9;
    padding-right: 6%;
    .label-text1{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 37%;
      }
    }
  }
}

.carousel-root{
  height: 49px;
  width: 100%;
}
.control-dots{
  .dot{
    opacity: 0 !important;
  }
}
.carousel-status{
  display: none;
}
.carousel.carousel-slider .control-arrow
{
  opacity: 0 !important;
}