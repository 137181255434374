.tab2-align {
    text-align: left;
    display: flex;
    height: 60px;
    align-items: center;
}

.tabbar {
    font-weight: 700;
    font-size: 11px;
    color: #5F6163;
}

.hashtag {
    margin-left: 5%;
    width: 4%;
}

.asset {
    width: 24%;
}

.price,
.hold,
.val {
    width: 16%;
    text-align: end;
}

.roi{
    width: 14%;
    text-align: end;
}

.equity-tag {
    border: 0.5px solid #E5E5E5;
    margin-top: -16px;
    margin-bottom: 0;
}

.equity-data {
    display: flex;
    text-align: left;
    padding-top: 32px;
    padding-bottom: 16px;
    transition: 0.4s ease;
}

.equity-data:hover{
    background: #f5f5f5;
    cursor: pointer;
    transform: scale(1.02);
}


.equity-num {
    font-weight: 700;
    font-size: 14px;
    color: #5F6163;
    margin-left: 5%;
    width: 4%;
    margin-top: 10px;
}

.equity-name {
    width: 24%;
}

.asset-name {
    font-weight: 700;
    font-size: 14px;
    color: #5F6163;
    margin-left: 8px;
}

.asset-symbol {
    display: flex;
    margin-left: 37px;
    margin-top: -10px;
}

.asset-tag {
    font-weight: 400;
    font-size: 9px;
    align-items: center;
    color: #5F6163;
}

.asset-curr {
    font-weight: 700;
    font-size: 9px;
    color: #5F6163;
}

.asset-img {
    margin-top: -17px;
    margin-left: 5px;
}

.equity-price , .equity-hold , .equity-value{
    font-weight: 700;
    font-size: 14px;
    color: #5F6163;
    margin-top: 6px;
    width: 16%;
    text-align: end;
}

.equity-roi {
    font-weight: 700;
    font-size: 14px;
    color: #4CAF50;
    margin-top: 6px;
    width: 14%;
    text-align: end;
}

.hr-sty-equ{
    border: 0.5px solid rgba(238, 238, 238, 0.42);
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}