.alice-carousel__dots {
  display: none !important;

}
.Main-Desktop-Screen{
  display: block;
}
.mobile-stats {
  display: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;
}

.inr-img {
  width: 355px;
  height: 63.52px;
  margin-bottom: 1.5rem;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
}

.opt-home {
  display: flex;
  gap: 90px;
  justify-content: center;
  margin-top: 3rem;
  padding-left: 10px;

  input {
    width: 73px !important;
    height: 73px;
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    margin-right: 10px;

    &:active {
      border: 0.5px solid #E5E5E5;
    }

    &:focus {
      border: 0.5px solid #E5E5E5;
      outline: -webkit-focus-ring-color auto 0px !important;
    }
  }
}

.text-model {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #5F6163;
}

.Model-home-page {

  max-width: 443px !important;

  .modal-content {
    border-radius: 25px;

    .modal-body {
      padding-top: 49px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 57px;
      text-align: center;
    }

  }
}

.button-right-home {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;

  justify-content: center;
  padding-top: 2rem;

  .label2 {
    background: #fff;
    border: 0.5px solid #E5E5E5;
    border-radius: 25px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    color: #5F6163;
    height: 45px;
    padding-left: 32px;
    padding-right: 36px;
    justify-content: center;
    z-index: 20;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;

    &:hover {
      transform: scale(1.1);

    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #4CAF50;
      border: 0.5px solid #4CAF50;
    }

    &:focus {
      background: #4CAF50;
      border: 0.5px solid #4CAF50;
      box-shadow: 0 0 0 0 #4CAF50;
    }
  }

}



.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 0px !important;
  display: block !important;
  width: 0px !important;
}

.custom-carsolue {
  display: none;
  margin-top: 108px;
.owl-carousel-mobile{
  padding-left: 42.1px;
  padding-right: 42.1px;
}
  .rightsideimge {
    position: absolute;
    top: 39%;
    right: 5%;
    transform: translateY(-42%);
  }

  .leftsideimag {
    position: absolute;
    top: 39%;
    left: 5%;
    transform: translateY(-42%);
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #5F6163;
    padding-top: 1rem;
  }

  .contianer-mobile {
    background: -webkit-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
    background: -o-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
    background: -moz-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
    background: linear-gradient(110deg, #4CAF50 80%, #fff 20%);
    width: 100%;
    height: 81px;
    display: flex;
    align-items: center;
    padding-left: 42.1px;
    padding-right: 42.1px;
    margin-bottom: 3rem;

    h1 {

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 55px;
      /* or 138% */
      margin: 0;
      display: flex;
      align-items: center;
padding-top: 0px;
      color: #FFFFFF;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 42px;
    /* or 280% */

    display: flex;


    color: #5F6163;

    margin-bottom: 1rem;

    width: 100%;
  }

  .sub {
    background-color: #EBEBEB;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    .imgsectiom {
      background-color: white;
      border-radius: 15px;
      width: 80%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: 2.5rem;

      img {

        height: 369px;

        padding-top: 23px;
      }
    }

    .textdata {
      width: 100%;
      background: white;
      margin-top: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 9px;
      padding-bottom: 5px;

      h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #5F6163;
      }

      h5 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #5F6163;

      }
    }
  }
}

.secondbg {
  background: #4CAF50;
  border: 0.5px solid #4CAF50;
}

.sec1-custom {
  margin-top: 108px;
}

.numalign {
  padding: 20px 0px;
}

.numdigit {
  font-weight: 700;
  font-size: 65px;
  color: #FFFFFF;
  text-align: center;
}

.numtext {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  margin-top: -30px;
}

.numsdata {
  justify-content: space-between;
  display: flex;
  padding: 0px 43px;
}

.endalign {
  text-align: end;
}


.homepaage {
  display: flex;
  /* margin-top: 158px; */
  /* height: calc(42rem - 108px); */
  padding-left: 89px;
  padding-right: 90px;
  height: calc(100vh - 140px);
  padding-top: 109px;
  // padding-right: 8.5%;

  .lefts {
    width: 40%;
    display: flex;

    align-items: flex-end;
    border-bottom: 1px solid #EBEBEB;
    ;

    img {
      width: 423px;
      height: 423px;
    }
  }

  .left {
    width: 40%;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #EBEBEB;

    img {

      height: 97%;
    }
  }

  .right {
    width: 60%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    margin-left: 100px;

    h3 {
      display: none;
    }

    .sub {
      .mar {
        margin-top: 2rem;

        h6 {
          display: none;
        }
      }

      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 57px;
        line-height: 72x;
        display: flex;
        align-items: center;
        margin: 0;
        color: #5F6163;
      }

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 47px;
        /* or 210% */
       
        margin: 0;
        display: flex;
        align-items: center;
        width: 48rem;

        color: #5F6163;
      }

      .sectionlabel {
        display: flex;
        align-items: center;

      }

      .cliselabel2 {
        display: none !important;
      }

      .label1 {
        background: #FFFFFF;
        border: 0.5px solid #EBEBEB;
        border-radius: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        height: 55px;
        width: 200px;
        color: #5F6163;
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 3rem;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {
          font-size: 17px;
          background: #fff;
        }

        &:after {

          background: #4CAF50;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {}

        &:focus {}
      }

      .label2 {
        background: #4CAF50;
        border: 0.5px solid #4CAF50;
        border-radius: 15px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        color: #FFFFFF;
        height: 55px;
        width: 250px;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {
          font-size: 17px;
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:focus {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
          box-shadow: 0 0 0 0 #4CAF50;
        }
      }
    }
  }
}



.custompage {
  display: flex;
  /* margin-top: 108px; */
  height: calc(46rem - 108px);
  padding-left: 90px;
  padding-right: 90px;

  .lefts {
    width: 40%;
    display: flex;
    align-items: center;

    img {
      width: 423px;
      height: 423px;
    }
  }

  .leftsc {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: end;

    img {
      width: 423px;
      height: 423px;
    }
  }

  .right {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: end;

    .sub {
      width: 86%;

      .mar {
        margin-top: 2rem;

        h6 {
          display: none;
        }
      }

      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 52px;
        line-height: 63px;
        display: flex;
        align-items: center;
        margin: 0;
        color: #5F6163;
      }

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        /* or 210% */

        margin: 0;
        display: flex;
        align-items: center;

        color: #5F6163;
      }

      .sectionlabel {
        display: flex;
        align-items: center;

      }

      .cliselabel2 {
        display: none !important;
      }

      .label1 {
        background: #FFFFFF;
        border: 0.5px solid #EBEBEB;
        border-radius: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        height: 55px;
        width: 200px;
        color: #5F6163;
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 3rem;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {
          font-size: 17px;
          background: #fff;
        }

        &:after {

          background: #4CAF50;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {}

        &:focus {}
      }

      .label2 {
        background: #4CAF50;
        border: 0.5px solid #4CAF50;
        border-radius: 15px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        color: #FFFFFF;
        height: 55px;
        width: 250px;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {
          font-size: 17px;
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:focus {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
          box-shadow: 0 0 0 0 #4CAF50;
        }
      }
    }
  }
}

.alabout {
  padding-left: 90px;
  padding-right: 90px;
  margin-top: 9rem;
  padding-bottom: 1.4rem;
  display: block;

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 73px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #5F6163;

  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 42px;
    /* or 233% */

    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5F6163;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8%;
    flex-wrap: wrap;
    width: 100%;

    margin-bottom: 4rem;

    .sub {
      width: 28%;
      margin-top: 4rem;

      .imgsectiom {
        width: auto;
        height: 392px;
        display: flex;
        border-radius: 45px;
        align-items: flex-end;
        justify-content: center;
        background: #FFFFFF;
        border: 0.5px solid #E5E5E5;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {}

        &:after {
          background: black;
          content: "";
          height: 492px;
          left: -190px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: 10;
        }

        &:hover:after {
          left: 140%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:focus {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
          box-shadow: 0 0 0 0 #4CAF50;
        }

        img {
          width: auto;
          height: 365px;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 0.8rem;
        color: #5F6163;

      }

      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 0.8rem;
        color: #5F6163;
      }
    }

  }
}

@media only screen and (max-width: 1100px) {
  .alabout {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 700px) {
  .alabout {
    display: none;
  }

  .homepaage {

    padding-left: 42.1px;
    padding-right: 42.1px;

    .lefts {
      width: 40%;
      display: flex;

      align-items: flex-end;
      border-bottom: 0px solid #EBEBEB;
      ;

      img {
        width: 423px;
        height: 423px;
      }
    }

    .left {
      width: 40%;
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #EBEBEB;
      ;

      img {
        width: 416px;
        height: 504px;


      }
    }

    .right {
      width: 60%;
      display: flex;
      align-items: center;
      border-bottom: 0px solid #EBEBEB;
      ;

      .sub {
        h6 {
          display: none;
        }

        .mar {
          margin-top: 2rem;
        }

        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: 57px;
          line-height: 63px;
          display: flex;
          align-items: center;
          margin: 0;
          color: #5F6163;
        }

        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 42px;
          /* or 210% */
        
          margin: 0;
          display: flex;
          align-items: center;

          color: #5F6163;
        }
      }
    }
  }



  .custompage {
    display: flex;
    /* margin-top: 108px; */
    height: calc(42rem - 108px);
    padding-left: 42.1px;
    padding-right: 42.1px;

    .lefts {
      width: 40%;
      display: flex;
      align-items: center;

      img {
        width: 423px;
        height: 423px;
      }
    }

    .leftsc {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: end;

      img {
        width: 423px;
        height: 423px;
      }
    }

    .right {
      width: 60%;
      display: flex;
      align-items: center;

      .sub {
        width: 86%;

        h6 {
          display: block;
        }

        .mar {
          margin-top: 2rem;

        }

        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: 57px;
          line-height: 63px;
          display: flex;
          align-items: center;
          margin: 0;
          color: #5F6163;
        }

        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 42px;
          /* or 210% */

          margin: 0;
          display: flex;
          align-items: center;

          color: #5F6163;
        }

      }
    }
  }




}


@media screen and (min-width:1600px) {
  .homepaage {
    padding-left: 135px;
    padding-right: 138px;


    .left img {
      height: 94%;
    }
  }

  .numsdata,
  .custompage {
    padding: 0 140px;
  }

  .alabout {
    padding: 0 140px;
  }
}



@media only screen and (max-width: 1400px) {
  .homepaage {
    height: 100vh;

    .left .img {

      height: 97%;
    }
  }

}

@media only screen and (max-width: 1000px) {
  .custom-carsolue {
    display: block;
    margin-top: 4rem;
    // height: 42rem;


  }
  .Main-Desktop-Screen {

    display: none;
  }
  .custompage {
    flex-direction: column;
    height: auto;

    .lefts {
      width: 100%;
      justify-content: center;
      margin-top: 8rem;

      img {
        width: 315px;
        height: 299px;
      }
    }

    .leftsc {
      width: 100%;
      justify-content: center;
      margin-top: 8rem;

      img {
        width: 315px;
        height: 299px;
      }
    }

    .right {
      order: 2;
      width: 100%;
      display: flex;
      align-items: center;

      .sub {
        width: 100%;

        h1 {
          font-weight: 800;
          font-size: 30px;
          line-height: 37px;
          justify-content: center;
          text-align: center;
          margin-top: 2rem;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 33px;
          margin: 0;
          display: flex;
          align-items: center;
          color: #5F6163;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }



  .homepaage {
    height: auto;

    .left {
      display: none;
    }

    .right {
      width: 100%;
      padding-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px;

      .sub {
        width: 100%;

        .label1 {
          justify-content: center;
          margin-right: 0;
          width: 70%;
          align-items: center;

          margin-top: 2rem;
          height: 59.64px;
          font-size: 18px;
        }

        .label2 {
          justify-content: center;
          margin-right: 0;
          width: 70%;
          align-items: center;

          margin-top: 1rem;
          height: 59.64px;
          font-size: 18px;
        }

        .sectionlabel {
          display: block;
        }

        h1 {
          font-weight: 800;
          font-size: 45px;
          line-height: 55px;
          /* or 122% */


          align-items: center;
          display: none;
          color: #5F6163;
        }

        h3 {
          font-weight: 800;
          font-size: 45px;
          line-height: 55px;
          /* or 122% */
          font-family: 'Montserrat';
          font-style: normal;
          display: flex;
          align-items: center;

          color: #5F6163;
          justify-content: center;
        }

        h6 {
          font-size: 15px;
          justify-content: center;
          padding: 0px;
          line-height: 36px;
          color: #5F6163;


        }

        p {

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 40px;
          /* or 250% */
          width: 100%;

          display: flex;

          flex-wrap: wrap;
          color: #5F6163;

        }



      }
    }
  }


  .mobile-stats {
    margin-top: 5rem;
    display: block;

    .mobile-stats-section {
      padding-left: 42.1px;
      padding-right: 42.1px;
    }

    .CardSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EBEBEB;
      height: 190px;

      img {
        width: 31px;
        height: 31px;
      }

      .leftside-mobile {
        h1 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 4rem;


          align-items: center;
          letter-spacing: 0.002em;

          color: #5F6163;
          margin-bottom: 0;
        }

        h3 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 30px;
          /* or 40px */


          align-items: center;
          letter-spacing: 0.002em;

          color: #5F6163;
        }
      }
    }

    .contianer-mobile {
      background: -webkit-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
      background: -o-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
      background: -moz-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
      background: linear-gradient(110deg, #4CAF50 80%, #fff 20%);
      width: 100%;
      height: 81px;
      display: flex;
      align-items: center;
      padding-left: 42.1px;
      padding-right: 42.1px;

      h1 {

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 55px;
        /* or 138% */
        margin: 0;
        display: flex;
        align-items: center;

        color: #FFFFFF;
      }
    }
  }


}