.dropcontainer{
    padding-top: 0px;
    padding-bottom: 15px;
    width: 100vw;
    transition: all ease 0.4s;
    position: fixed;
    z-index: 99;
    /* top: 0; */
    background: white;
    overflow: scroll;
    height: 74vh;
    width: 78%;
    margin: auto;
    right: 0;
    top: 95px;
    border-radius: 35px;
    border:1.5px solid #EDEDED;
    left: 0;
}
.leftdraw{
    transition: all ease 0.4s;
    top: -100vh  !important;
}
.rightdraw{
    transition: all ease 0.4s;
    top: 95px !important;
}
.alldropdowns{
    /* height: calc(100vh - 110px); */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    transition: all ease .3s;
    gap:2%;
    /* justify-content: space-between; */
    background-color: white;
   
}
.imgdrop{
    height: 61px;
}

.eachdropdown{
    border: none;
    outline: none;
    color:#000;
    width: 31.33%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 2rem;
    background-color: white;
 
}

.eachdropdown:hover{
    box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
    border-radius: 25px;
}

.droptitle {
    margin-top: 28px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #1F304F;
}
.droptext {
    margin-top: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #1F304F;
    text-align: center;
}

.divide-text{
    width: 100vw;
    height: 170px;
    font-weight: 500;
    font-size: 25px;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    padding-left: 11%;

 
    padding-right: 11%;
    color: #343C5B;
}
@media only screen and (min-width: 0px) and (max-width: 900px) {
    .divide-text{
        padding-left: 7%;

        padding-right: 7%;
    }
    .alldropdowns{
        padding-left: 7%;

        padding-right: 7%;
    }
    .eachdropdown{
        width: 100vw;
        align-items: center;
        text-align: center;
        padding-left: 0px;
        img{
            height: 71px;
        }
    }

    .droptext {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 5px;
        color: #1F304F;
    }
    .droptitle {
        margin-top: 14px;
        font-weight: 600;
        font-size: 19px;
        line-height: 45px;
        color: #1F304F;
    }
    .divide-text {
        width: 100vw;
        height: 83px;
        font-weight: 500;
        font-size: 16px;
        border-top: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;
        display: flex;
        align-items: center;
        padding-left: 11%;
        padding-right: 11%;
        color: #343C5B;
    }
}