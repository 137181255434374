.cusom
{
    .cusomsub{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #EBEBEB;
    ;
    height: 6rem;
    padding-left: 6%;
    padding-right: 6%;
    .sub{
        display: flex;
        align-items: center;
        p{
            color: #182542;
            font-size: 20px;
            font-weight: 700;
margin: 0;
margin-left: 0.5rem
        }
       
    }
    .sub1{

        img{
            width: 2rem; 
        }
    
    }

 
}
.tabcontiner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6%;
    padding-right: 6%;
    border: 0.5px solid #EBEBEB;
    height: 4rem;
    padding-left: 5%;
    padding-right: 5%;
    p{
        color: #182542;
        font-size: 16px;
        font-weight: 300;
margin: 0;
    }
}
}

.my-maps{
    width: 569px !important;
    .modal-body
    {
        padding: 0 !important;
    }
    .modal-content{
        height: 28rem;
    }
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;

    background-color: #ffffffa6 ;
}