// base
.flip {
  position: relative;
  overflow: hidden;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    /* or 129% */

    margin-bottom: 0px;

    color: #ffffff;
  }
  P {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    /* or 225% */
    margin-bottom: 0;
    margin: 0;
    color: #ffffff;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 45px;

    display: flex;
    align-items: center;

    color: #212224;
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    /* or 176% */
    margin-bottom: 1rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;

    color: #5f6163;
  }
  label {
    font-style: normal;
    font-weight: 800;
    font-weight: 800;
    font-size: 19px;
    /* or 221% */
    background: #4caf50;
    border-radius: 15px;
    height: 54px;
    width: 90%;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    &:hover {
      transform: scale(1.1);
    }
  }
  h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    /* or 225% */

    color: #5f6163;
  }
  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }
  > .front {
    //transform: rotateY(0deg);
  }
  > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    // transform: rotateY(-180deg);
  }
  &:hover {
    > .front {
      cursor: pointer;
      // display: none;
    }
    > .back {
      opacity: 1;
      display: flex;
      cursor: pointer;
    }
  }
  &.flip-vertical {
    > .back {
      // display: none;
    }
    &:hover {
      > .front {
        // display: none;
      }
      > .back {
        display: flex;
      }
    }
  }
}

// custom
.flip {
  position: relative;
  display: flex;
  // margin-right: 2px;

  width: 33.33%;
  height: 100vh;
  // padding-top: 72px;
  .front {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: flex-end;
    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    background-color: #4d4c4c;
    background-blend-mode: multiply;
    width: 100%;
    padding-bottom: 2rem;
    padding-left: 5%;
    img {
      margin-bottom: 1rem;
    }
  }
  > .back {
    display: block;
    //color: white;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;

    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    padding-bottom: 5rem;
    padding-left: 5%;
    padding-right: 5%;
    background: #fff;
    width: 100%;
    .backImg {
      margin-bottom: 1rem;
    }
    .about-secton {
      // display: flex;
      align-items: center;
      position: absolute;

      bottom: 2rem;
      width: 100%;
      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        color: #212224;
      }
      .box {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(40, 55, 144, 0.95);
        border-radius: 5px;
        margin-left: 10px;
        img {
        }
      }
    }
    p {
      font-size: 0.9125rem;
      line-height: 160%;
      color: #999;
    }
  }
}

.innerbannerLanding {
  height: 59px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 3.5%;
  justify-content: space-between;
  padding-right: 3.4%;
  background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;

    color: #ffffff;
    margin-bottom: 0px;
  }
  h6 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    color: #ffffff;
  }
}

.flipCard {
  width: 50% !important;
  .front {
    padding-left: 6% !important;
  }
  .back {
    padding-left: 6% !important;
  }
}

.cardSection {
  display: flex;
  background: black;
  align-items: center;
}

@media only screen and (max-width: 991.9px) {
  .innerbannerLanding {
    h5 {
      display: none;
    }
  }
  .landingpage {
    height: 100vh;
    position: relative;
    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    h5 {
      display: none;
    }
    h1 {
      margin-bottom: 150px;
    }
    span {
      font-style: normal;
      font-weight: 800;
      font-size: 41px;
      line-height: 55px;
      display: flex;
      align-items: center;
      width: 100%;
      color: #ffffff;
    }
    .props-object-landling {
      display: block;
      align-items: center;
      width: 93.2%;
      position: absolute;
      bottom: 2rem;

      justify-content: space-between;
      label {
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        margin-top: 1.5rem;
      }
    }
  }

  .cardSection {
    display: block;
    background: black;
    align-items: center;
  }
  .flip {
    position: relative;
    display: flex;
    width: 100%;
    .front {
      padding-left: 4%;
    }
    .back {
      padding-left: 4%;
    }
  }
  .flipCard {
    width: 100% !important;
    .front {
      padding-left: 4% !important;
    }
    .back {
      padding-left: 4% !important;
    }
  }
}


@media only screen and (max-width: 991.9px) {
  .flip {
    position: relative;
    overflow: hidden;
    height: 50vh;
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      /* or 129% */
  
      margin-bottom: 0px;
  
      color: #ffffff;
    }
    P {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      /* or 225% */
      margin-bottom: 0;
      margin: 0;
      color: #ffffff;
    }
  }


}