.footer {
    background-color: #4CAF50;
    display: flex;
    padding-left: 7%;
    padding-right: 8.5%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;

    .left {
        img {
            width: auto;
            height: 30px;

        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 200;
            font-size: 13px;
            line-height: 25px;
            /* or 192% */

            display: flex;
            align-items: center;

            color: #FFFFFF;

            span {
                font-weight: bold;
                margin-right: 5px;
            }
        }
    }

    .right {
        width: 50%;

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            margin-bottom: 41px;

            color: #FFFFFF;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }

            ;
        }
    }
}

@media only screen and (max-width: 700px) {

    .footer {
        display: none;
    }
}