.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;

}

.blackscreen {
  height: 100vh;
  width: 100%;
  background-color: #0000009c;
  z-index: 30;
  position: fixed;
  top: 0;
}

.the-nav-card {
  width: 100%;
  height: 70px;
  background: white;
  border: 1px solid black;
}

.desk {
  display: block;
}

.mobo {
  display: none;
}


.vl-top {
  border-left: 0.5px solid #E5E5E5;
  height: 100%;
  z-index: 10;
}

.searchsty {
  font-weight: 700;
  font-size: 16px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #5F6163;
  opacity: 0.25;
  margin-left: 20px;
  border: none;
  width: 25%;
  margin-top: 8px;
}

.vl-topnav {
  border-left: 0.5px solid #E5E5E5;
  height: 75px;
}

.investor-class {
  padding-left: 13px;
  padding-right: 12px;
}

.active {
  margin-left: 20px !important;
  margin-right: 20px !important;
  font-size: 15px !important;
  font-weight: 800 !important;
}

.menu-items {
  justify-content: center;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 10px;
}


.custom_navbar1e {

  padding-right: 0%;
  position: fixed;
  background: #ffffff;

  top: 32px;
  z-index: 66;
  width: 100%;

  .mobile {
    height: 76px;
    border-bottom: 0.5px solid #e5e5e5b3;
    padding-left: 43px !important;
    // padding-right: 7% !important;

  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0rem;
    transition: box-shadow 0.15s ease-in-out;
    left: 0;
    position: absolute;
    top: 20px;


  }

  .nav-link {
    color: #5F6163 !important;
    // margin-right: 19px !important;

  }


  // nav .navbar-collapse.show {
  //   left: 
  // }
  .edit {
    display: none;
  }

  .navbar-brand {
    transition: 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .navbar-nav {
    background-color: white;
  }

  .navbar-toggler-icon {
    background-image: url('../Image/burger.png') !important;
    width: 19px;
    height: 36px;
  }

  .nav-link {
    // font-style: normal;
    // font-weight: 400;
    // font-size: 18px;
    // line-height: 22px;
    // display: flex;
    // align-items: center;
    // text-align: right;

    white-space: nowrap;
    // color: #5F6163;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5F6163;

    &:hover {
      color: #5F6163;
      transform: scale(1.1);
    }

    &:active {
      color: #5F6163;
    }
  }

  .custom-last-button1 {
    width: 126px;
    height: 76px;
    border-radius: 0px;
    background: #CD1E25;
    border: none !important;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;

    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:focus {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
      box-shadow: 0 0 0 0 #CD1E25;
    }
  }

  .custom-first-buttom1 {
    width: 126px;
    height: 76px;
    border: none !important;
    background: #5F6163;
    margin-left: 10px;
    border-radius: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;
    border: 0.5px solid white;

    &:hover {
      font-size: 15px;
      background: #5F6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5F6163;
      border: 0.5px solid #5F6163;
    }

    &:focus {
      background: #5F6163;
      border: 0.5px solid #5F6163;
      box-shadow: 0 0 0 0 #5F6163;
    }
  }








  .custom-last-button {
    width: 126px;
    height: 76px;
    border-radius: 0px;
    background: #CD1E25;
    border: none !important;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;

    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:focus {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
      box-shadow: 0 0 0 0 #CD1E25;
    }
  }

  .custom-first-buttom {
    width: 126px;
    height: 76px;
    border: none !important;
    background: #5F6163;
    margin-left: 10px;
    border-radius: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;
    border: 0.5px solid white;

    &:hover {
      font-size: 15px;
      background: #5F6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5F6163;
      border: 0.5px solid #5F6163;
    }

    &:focus {
      background: #5F6163;
      border: 0.5px solid #5F6163;
      box-shadow: 0 0 0 0 #5F6163;
    }
  }
}





.custom_navbar {
  padding-left: 4%;
  padding-right: 4%;
  display: none;
  background: #5F6163;
  width: 100%;

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0rem;
    transition: box-shadow 0.15s ease-in-out;
    left: 0;
    position: absolute;
    top: 20px;
  }

  // nav .navbar-collapse.show {
  //   left: 0;
  // }

  .navbar-brand {
    transition: 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    /* or 214% */
    padding: 0.5rem 2rem;
    display: block;
    align-items: center;
    transition: 1s;
    color: white;
    white-space: nowrap;

    &:hover {
      color: white;
      transform: scale(1.1);
    }
  }

  .custom-last-button {
    width: 102px;
    height: 36px;

    background: #CD1E25;
    border: 0.5px solid #CD1E25;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-left: 1rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
    }

    &:focus {
      background: #CD1E25;
      border: 0.5px solid #CD1E25;
      box-shadow: 0 0 0 0 #CD1E25;
    }
  }

  .custom-first-buttom {
    width: 102px;
    height: 36px;

    background: #5F6163;
    border: 0.5px solid #5F6163;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    border: 0.5px solid white;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #5F6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5F6163;
      border: 0.5px solid #5F6163;
    }

    &:focus {
      background: #5F6163;
      border: 0.5px solid #5F6163;
      box-shadow: 0 0 0 0 #5F6163;
    }
  }
}

@media screen and (min-width: 1600px) {
  .img-space {
    padding-right: 4vw;
  }

  .active {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .menu-items {
    justify-content: center;
    margin-left: 37px;
    margin-right: 37px;
  }

  .custom_navbar1e {


    .mobile {
      padding-left: 70px !important;
      // padding-right: 7% !important;

    }

  }
}

@media only screen and (min-width: 1600px) {
  .investor-class {
    padding-left: 37px;
    padding-right: 36px;
  }
}

@media only screen and (max-width: 991.9px) {
  .desk {
    display: none;
  }

  .mobo {
    display: block;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem !important;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-top: 3rem;
  }

  .custom_navbar1e {

    .nav-link {
      font-style: normal;
      font-weight: 500;
      white-space: nowrap;
      font-size: 26px;
      line-height: 30px;
      padding: 0;
      display: block;
      align-items: center;
      transition: 1s;
      color: #5F6163;
      justify-content: center;
      margin: 0;
      margin-bottom: 22px;
      text-align: center;
    }

    .mobile {
      padding-left: 0% !important;
      padding-right: 0% !important;
    }

    .custom-last-button {
      display: none;
    }

    .custom-last-button1 {
      display: none;
    }

    .custom-first-buttom1 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      /* padding: 0.5rem 2rem; */
      display: block;
      align-items: center;
      transition: 1s;
      color: #5F6163;
      justify-content: center;
      margin: 0;
      margin-bottom: 22px;
      width: auto;
      background: transparent;
      margin-top: 0px;
      height: auto;
      padding-left: 0;

      &:focus {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        /* padding: 0.5rem 2rem; */
        display: block;
        align-items: center;
        transition: 1s;
        color: #5F6163;
        justify-content: center;
        margin: 0;
        margin-bottom: 22px;
        width: auto;
        background: transparent;
        margin-top: 0px;
        height: auto;
        padding-left: 0;
      }

      &:active {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        /* padding: 0.5rem 2rem; */
        display: block;
        align-items: center;
        transition: 1s;
        color: #5F6163;
        justify-content: center;
        margin: 0;
        margin-bottom: 22px;
        width: auto;
        background: transparent;
        margin-top: 0px;
        height: auto;
        padding-left: 0;
      }
    }

    .custom-first-buttom {

      position: absolute;
      bottom: 0;
      width: 60%;
      margin: 0;
      bottom: 45px;
      background: #CD1E25;
      border-radius: 43px;
      font-size: 18px;
      margin: auto;
    }

    nav {

      //   .navbar-collapse.show {
      //     left: 0px;
      // height: 50vh;
      // margin-top: 3rem;
      // background: white;
      // width: 100%;
      // max-width: 100%;
      // }
      .navbar-collapse.show {
        padding-left: 0%;
        background: white;

        z-index: 9999999;
        background: white;
        z-index: 9999999;
        position: fixed;
        top: 0;
        margin-top: 105px;
        right: 0;
        width: 100%;
        display: block;
        align-items: center;
        width: 100%;
        justify-content: center;

      }

      .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 0px solid transparent;
        border-radius: 0rem;
        transition: box-shadow 0.15s ease-in-out;
        left: 0px;
        position: relative;
        top: 0px;
        padding-left: 45.5px;
      }

      .edit {
        display: block;
        position: absolute;
        top: 0;
        width: 209px;
        bottom: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-41%) translateY(-51%);
        -webkit-transform: translateX(-41%) translateY(-51%);
        transform: translateX(-41%) translateY(-51%);
      }
    }

    .custom-first-buttom {}

    .custom-last-button {
      margin-left: 28px;
    }

    .navbar-brand {
      transition: 1s;
      display: none;
    }
  }
}


@media only screen and (max-width: 991.9px) {

  .custom_navbar1e {
    .navbar-nav h6 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 2rem;
      color: #5F6163;

    }

    .sectionlabel-mobile {
      .label1 {
        background: #4CAF50;
        border: 0.5px solid #4CAF50;
        border-radius: 15px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        color: #FFFFFF;
        height: 50px;
        width: 150px;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;
        font-family: 'Montserrat';
        font-style: normal;
        margin: auto;
        margin-top: 1rem;
        width: 181px;
        border-radius: 15px;
        margin-bottom: 2rem;

        &:hover {
          font-size: 17px;
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
        }

        &:focus {
          background: #4CAF50;
          border: 0.5px solid #4CAF50;
          box-shadow: 0 0 0 0 #4CAF50;
        }
      }
    }
  }
}