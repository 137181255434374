.carres {
    height: 40px;
    background-color: white;
    ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    z-index: 999;
    border-bottom: 0.5px solid #E5E5E5;
    cursor: pointer;

    h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;


        margin-bottom: 0;
        color: #FFFFFF;

        img {
            width: 18px;
            height: 18px;
        }
    }
}

// .left-icon  {
//     margin-left: 12px;
//     margin-right: 12px;
// }


.vl-top1 {
    border-left: 0.5px solid #E5E5E5;
    height: 40px;
}

.nvest-card {
    position: absolute;
    background: white;
    z-index: 12;
    left: 8px;
    top: 45px;
}

.nvest-img {
    border: 0.5px solid #EBEBEB;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
}

@media only screen and (max-width: 800px) {
    .carres {
        height: 2rem;
        // background-color: #4CAF50;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
        position: fixed;
        width: 100%;
        z-index: 999;
        padding-left: 5%;

        h5 {
            
            font-size: 11px;
        }
    }

   

    .vl-top1 {
        height: 33px;
    }

    .numsdata {
        display: block;
        padding: 0px;
    }

    .numalign,
    .endalign {
        text-align: center;
    }

    .homepaage .right {
        margin-left: 0px;
    }

}


@media only screen and (max-width: 800px) {
    .numdigit {
        font-size: 55px;
    }

    .carres {
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0%;
        position: fixed;
        width: 100%;
        z-index: 999;
        padding-left: 0%;
    }
}
@media only screen and (max-width: 1000px) {
.secondbg {
    display: none;
}
}