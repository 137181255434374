.what-we-do {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
}
.what-we-do-mobile {
display: none;
}
.what-we-do {
    padding-bottom: 4rem;
}

.button-right-what-we-do {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding-bottom: 1rem;
    justify-content: start;
}

.right-what-we-d0 {
    width: 60%;
    padding-right: 5%;
    padding-top: 6rem;
    padding-left: 88px;

    .check-detail-what-we-do {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;

        img {
            margin-right: 10px;
            width: 21px;
            height: 21px;
        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            margin: 0;
            color: #5F6163;
        }
    }

    h4 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        display: flex;
        align-items: center;
        padding-top: 1rem;
        color: #4CAF50;
    }

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 55px;
        line-height: 67px;
        display: flex;
        align-items: center;

        color: #5F6163;
        margin-bottom: 2rem;
    }

    .label2 {
        background: #fff;
        border: 0.5px solid #E5E5E5;
        border-radius: 25px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
        color: #5F6163;
        height: 45px;
        padding-left: 34px;
        padding-right: 38px;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;

        &:hover {
            transform: scale(1.1);

        }

        &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
        }

        &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
            background: #4CAF50;
            border: 0.5px solid #4CAF50;
        }

        &:focus {
            background: #4CAF50;
            border: 0.5px solid #4CAF50;
            box-shadow: 0 0 0 0 #4CAF50;
        }
    }

}

.left-what-we-d0 {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 40%;
    align-items: flex-end;
    padding-right: 90px;

    .we1 {
        width: 191px;
        height: 191px;
        position: relative;
        bottom: -26px;
        right: 80px;
    }

    .inner1-what-we-do {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .second-img-what-we-do {
        display: flex;
        position: relative;
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;
    }

    .we2 {
        width: 282px;
        height: 282px;

    }

    .we3 {
        width: 189px;
        height: 189px;
        position: relative;
        right: 138px;


    }

    .we4 {
        width: 184px;
        height: 184px;
        position: relative;
        bottom: -28px;
        left: 1%;
    }
}

@media screen and (max-width:1100px) {












    .what-we-do {
        display: block;
        padding-top: 0rem;
    }

    .right-what-we-d0 {
        padding-left: 42.1px;
        padding-right: 42.1px;
        padding-top: 2rem;
        width: 100%;

        h4 {
            font-size: 20px;
        }

        h1 {
            font-size: 50px;
            line-height: 55px;
        }

        .check-detail-what-we-do {
            margin-top: 1rem;
        }
    }

    .left-what-we-d0 {
        padding-right: 10px;
        display: none;
        padding-right: 0%;
    }
}

@media screen and (min-width:1600px) {
    .button-right-what-we-do {

        padding-bottom: 2rem;
    }

    .right-what-we-d0 {
        padding-left: 138px;
        padding-right: 42.1px;

        .check-detail-what-we-do {
            margin-top: 2rem;
        }
    }

    .left-what-we-d0 {
        padding-right: 138px;
    }

    .left-what-we-d0 {

        .we4 {
            width: 234px;
            height: 234px;
            bottom: -44px;

        }

        .we2 {
            width: 322px;
            height: 322px;

        }

        .we1 {
            width: 241px;
            height: 241px;
            bottom: -51px;
            right: 91px
        }

        .we3 {
            width: 229px;
            height: 229px;
            right: 189px;
        }
    }
}

@media screen and (max-width:1000px) {
    .what-we-do{
        display: none;
    }
    .what-we-do-mobile {
        margin-top: 5rem;
        display: block;
        .section-mobile-detail {
            padding-left: 42.1px;
            padding-right: 42.1px;
            margin-bottom: 3rem;

            h2 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 800;
                font-size: 25px;
                line-height: 42px;
                /* or 168% */
                margin-top: 3rem;
                margin-bottom: 1.8rem;
                display: flex;
                align-items: center;

                color: #5F6163;
            }
        }

        .check-detail-what-we-do {
            display: flex;
            align-items: start;
            margin-top: 1.5rem;

            img {
                margin-top: 6px;
                margin-right: 10px;
                width: 21px;
                height: 21px;
            }

            p {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 30px;
                margin: 0;
                color: #5F6163;
            }
        }

        .contianer-mobile {
            background: -webkit-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
            background: -o-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
            background: -moz-linear-gradient(110deg, #4CAF50 80%, #fff 20%);
            background: linear-gradient(110deg, #4CAF50 80%, #fff 20%);
            width: 100%;
            height: 81px;
            display: flex;
            align-items: center;
            padding-left: 42.1px;
            padding-right: 42.1px;

            h1 {

                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 800;
                font-size: 35px;
                line-height: 55px;
                /* or 138% */
                margin: 0;
                display: flex;
                align-items: center;

                color: #FFFFFF;
            }
        }
    }
}