.personimg {
    margin-top: 215px;
    margin-right: 40px;
}

.rightimg {
    // height: 100vh;
    display: table-cell;
    justify-content: center;
    vertical-align: middle;
    top: 100px;
    width: 1%;
    text-align: end;
}

.leftdata {
    // height: 100vh;
    margin-top: 270px;
    display: flex;
    justify-content: center;
    padding-left: 43px;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.the-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: 10px;
}

.the-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 45px;
    color: #5F6163;
    width: 550px;
    margin-top: 20px;
}

.desc-cap {
    font-weight: 700;
}

.the-hrtag {
    position: absolute;
    width: 100%;
    left: 0%;
    overflow-x: hidden;
    /* top: 24px; */
    margin-top: -35px;
}

.num-title {
    font-weight: 700;
    font-size: 65px;
    letter-spacing: 0.002em;
    color: #4CAF50;
}

.num-text {
    font-weight: 600;
    font-size: 16px;
    color: #5F6163;
    margin-top: -30px;
}

.thepage {
    padding-left: 70px;
}

.num-align {
    margin-left: 20px;
}

.cap-align {
    margin-left: 66px;
}

.bottom-align {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #4CAF50;
}

.bottom-text {
    font-weight: 800;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    height: 50px;
    padding-top: 12px;
    cursor: pointer;
}

.bottom-text:hover {
    transform: scale(1.1);
    transition: 0.4s all ease;
}

.second-page {
    height: 100vh;
}

.thepage {
    height: 25vh;
    display: grid;
    align-items: center;
    margin-top: -70px;
}

.nums-second-data {
    justify-content: space-between;
    display: flex;
    padding: 20px 43px;
    margin-top: -25px;
}

.second-page-down {
    height: 70vh;
    display: table-cell;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: 1%;
}

.title-sec {
    font-weight: 800;
    font-size: 55px;
    color: #5F6163;
}

.sec-desc {
    font-weight: 400;
    font-size: 20px;
    color: #5F6163;
    width: 1000px;
    line-height: 40px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

.third-page {
    height: 60vh;
    margin-bottom: 100px;
}

.third-text {
    font-weight: 800;
    font-size: 55px;
    color: #5F6163;
    text-align: center;
    margin-bottom: 100px;
}

.the-hr-tag {
    margin-top: 100px;
}

.digit {
    font-weight: 700;
    font-size: 30px;
    text-align: end;
    color: #5F6163;
}

.starter-cards {
    padding-left: 20px;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
    border-top: 0.5px solid #E5E5E5;
    cursor: pointer;
}

.starter-cards1 {
    padding-left: 20px;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
    border-top: 0.5px solid #E5E5E5;
    cursor: pointer;
}

.starter-cards:hover,
.starter-cards1:hover {
    // transform: scale(1.1);
    // transition: 0.4s all ease;
    border-left: 0.5px solid #E5E5E5;
    z-index: 10;
    background: white;
    box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
    padding-bottom: 57px;

    // border: none;
    .right-img-arrow {
        margin-left: 4rem;
        // margin-top: 0.8rem;
    }

    .hov-text {
        color: #4CAF50;
        font-weight: 800;
        display: block;
        margin-top: -51px;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}

.starter-title {
    font-weight: 700;
    font-size: 25px;
    color: #5F6163;
}

.btm {
    margin-top: 100px;
}

.starter-desc {
    font-weight: 400;
    font-size: 12px;
    color: #5F6163;
}

// .align-starter {
//     display: flex;
// }


.vl {
    border-left: 0.5px solid #E5E5E5;
    height: 285px;
    margin-top: -17px;
}

.hr-style {
    margin-top: -16px !important;
}

.rightnum {
    margin-right: 20px;
}

.first-row,
.second-cards {
    display: flex;
}

.arrow-circle {
    width: fit-content;
    border-radius: 50px;
    background: #F3F4F5;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    cursor: pointer;
    box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
    transition: 0.4s all ease;
}

.right-img-arrow {
    padding: 10px;
}

.hov-text {
    display: none;
}

// .arrow-circle:hover + .hov-text{
//     width: 95px;
//     padding-left: 60px;
// }

//  .arrow-circle:hover + .hov-text {
//     color: #4CAF50;
//     font-weight: 800;
//     display: block;
//     margin-top: -51px;
//     margin-left: 10px;
//     cursor: pointer;
// }
// .starter-cards:hover{
//     box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
// }

// @media screen and (min-width:1600px) {
//     .starter-cards {
//         margin-left: 50px;
//         margin-bottom: 30px;
//         margin-right: 20px;
//     }
// }

@media screen and (min-width:1600px) {
    .leftdata {
        padding-left: 70px;
    }

    .nums-second-data {
        padding: 20px 70px;
    }
}